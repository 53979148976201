import React, { ReactNode, useState, CSSProperties, useCallback, useEffect, useContext, useRef } from 'react';
import styles from './styles.module.css';
import Image from 'next/image';
import { Search } from '@/features/autocomplete';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import { ETabIndexesOrderingLevels } from '@/types/types';
import { LocalizedLink } from '@/components/Link';
import { AnalyticsContext } from '@/features/arena-data/view';
export type TLink = {
  href: string;
  label: string;
  target: '_blank' | '_self' | '';
};
interface TopBarProps {
  zIndexStartVal: number;
  attributes: {
    logo?: string | null | undefined;
    logoLabel?: string | null | undefined;
    links: TLink[] | null | undefined;
    override_color_topbar_background?: string | null | undefined;
    override_color_item_normal_label?: string | null | undefined;
    override_color_item_hover_background?: string | null | undefined;
  };
  children?: ReactNode;
}
const tabIndexLogo: number = ETabIndexesOrderingLevels.THIRD as unknown as number;
const tabIndexTopbarLinks: number = ETabIndexesOrderingLevels.FOURTH as unknown as number;
export const TopBar: React.FC<TopBarProps> = ({
  zIndexStartVal,
  attributes: {
    logo = null,
    logoLabel = null,
    links = [],
    override_color_topbar_background = null,
    override_color_item_normal_label = null,
    override_color_item_hover_background = null
  },
  children
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const topbarRef = useRef<HTMLElement>(null);
  const {
    isNotPc
  } = useDeviceDetector();
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const topBarStyle = {
    '--background-color': override_color_topbar_background || '',
    'zIndex': zIndexStartVal + 1
  } as CSSProperties;
  const linkStyle: React.CSSProperties = {
    '--normal-label-color': override_color_item_normal_label || '',
    '--hover-background-color': override_color_item_hover_background || ''
  } as CSSProperties;
  const renderedLinks = links?.map((link: TLink, index: number) => <LocalizedLink prefetch='default' key={index} href={link.href} target={link.target} onClick={() => AITracks.topNavClick(link?.label ?? '', index)} className={['ark-ui-topbar-link', styles.link, '__max12ch'].join(' ')}>
			{link?.label?.substring?.(0, 12) || ''}
		</LocalizedLink>);
  const imageSrc = () => {
    if (logo == 'undefined' || logo == null) return <></>;
    return <Image className={`${styles.logo} ${isFocused ? styles.hiddenmobile : ''}`} src={logo} alt={logoLabel ?? 'Logo'} layout='responsive' quality={90} width={152} height={40} data-sentry-element="Image" data-sentry-component="imageSrc" data-sentry-source-file="index.tsx" />;
  };
  function blurSearchElement() {
    const active = document.activeElement;
    if (active?.classList.contains('ark-ui-search-active')) {
      (active as HTMLInputElement).blur();
    }
  }
  useEffect(() => {
    const desktopMql = window.matchMedia('(min-width: 1024px)');
    function tabindexLevelHandler(turnOff = false) {
      document?.body?.querySelector?.(`.${styles.homeLink}`)?.setAttribute('tabindex', tabIndexLogo.toString());
      (document?.body?.querySelectorAll('.ark-ui-topbar.topbar .ark-ui-topbar-link') || []).forEach((el: any) => {
        el.setAttribute('tabindex', tabIndexTopbarLinks); // first level is for burger icon / sidebar items
      });
    }
    return tabindexLevelHandler(true);
  }, []);
  useEffect(() => {
    if (typeof document === 'undefined' || !topbarRef.current || !isNotPc() || !isFocused) {
      return;
    }

    //setTimeout for iOS scrolling when focusing input - 400 is the CSS transition time
    setTimeout(() => document.addEventListener('scroll', blurSearchElement), 400);
    return () => {
      document.removeEventListener('scroll', blurSearchElement);
    };
  }, [isFocused, topbarRef.current]);
  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  return <header ref={topbarRef} className={['ark-ui-topbar topbar', styles.topbar].join(' ')} style={topBarStyle} data-sentry-component="TopBar" data-sentry-source-file="index.tsx">
			{logo?.length ? <LocalizedLink className={styles.homeLink} href='/' aria-label={logoLabel ?? undefined}>
					{imageSrc()}
				</LocalizedLink> : <></>}
			<nav style={linkStyle} className={`${styles.links} ${isFocused ? styles.hidden : ''}`}>
				{renderedLinks}
			</nav>
			<div className={`${styles.searchContainer} ${isFocused ? styles.focused : ''}`}>
				<Search isFocused={isFocused} onFocus={handleFocus} onBlur={handleBlur} data-sentry-element="Search" data-sentry-source-file="index.tsx" />
			</div>
		</header>;
};