export const getSearchParams = (queryString: string, name: string): string => {
	const search = new URLSearchParams(queryString);
	return search.get(name) ?? '';
};

export const buildHrefLocale = (href: string, locale: string) => {
	if (href.startsWith('/')) {
		return `/${locale}${href}`;
	} else if (href.startsWith('http')) {
		return href;
	} else {
		return `/${locale}/${href}`;
	}
};

export const cleanQueryParams = (searchParams: URLSearchParams, toExclude: string[]): string => {
	const cleanedParams = new URLSearchParams(searchParams);

	Array.from(cleanedParams.keys())
		.filter((key) => toExclude.some((unwanted) => key === unwanted || key.startsWith(unwanted)))
		.forEach((key) => cleanedParams.delete(key));

	return cleanedParams.toString();
};

export const getLocaleFromPathname = (pathname: string): string => {
	const locale = pathname.split('/')[1];
	return locale;
};
