import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Image from 'next/image';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { $isGDPRApplies, $isUS, openGDPRCmpPopupFx, openUSPCmpPopupFx } from '@/features/analytics/model';
import { $isMicrosoftDomain, $isSidebarHidden } from '@/app.model';
import { StaticImage } from '@/components/StaticImage';
import { $gameStateStore } from '@/features/games/model';
import { GameState } from '@/root/libs/enums/GameState';
import { LocalStorageService, LSKeys } from '@/libs/utils/local-storage';
import { isServer } from '@/libs/utils/isServer';
import { AnalyticsContext } from '@/features/arena-data/view';
export type TLink = {
  href: string;
  label: string;
  target: '_blank' | '_self' | '';
  icon: {
    url: string;
  };
};
export type GroupLinks = {
  link: TLink[];
};
export type TFooter = {
  logo: {
    url: string;
  } | null;
  groups: GroupLinks[] | null;
  socialLinks: TLink[] | null;
  backgroundColor: string | null;
  fontColor: string | null;
  text: string | null;
  manageCookiesLabel: string | null;
};
interface FooterProps {
  attributes: TFooter;
}
const SocialLinks = (socialLinks: TLink[] | null, AITracks: any) => {
  if (!socialLinks) return null;
  return socialLinks.filter(link => link.icon).map((link, i) => <li key={`socialLinks-${i}-${link.href}`}>
				<a href={link.href} target={link.target} className={styles.socialLink} aria-label={link.label} onClick={() => AITracks.footerTrackSocialLinkClick(link)}>
					<Image className='icon' src={link?.icon?.url} alt={link.label} quality={90} width={24} height={24} />
				</a>
			</li>);
};
const renderGroupLink = (li: TLink, i: number, AITracks: any) => <li key={`groupLink-${i}-${li.href}-${i}`} data-sentry-component="renderGroupLink" data-sentry-source-file="index.tsx">
		<a href={li.href} target={li.target} className={styles.groupLink} aria-label={li.label} onClick={() => AITracks.footerTrackGroupLinkClick(li)}>
			<span>{li.label}</span>
			{li?.icon && <Image className='icon' src={li?.icon?.url} alt={li.label} quality={90} width={30} height={14} />}
		</a>
	</li>;
type TOpenCmpPopup = () => Promise<void>;
const renderMicrosoftGroupLink = (AITracks: any) => {
  const li = {
    href: 'https://aka.ms/YourCaliforniaPrivacyChoices',
    label: 'Your Privacy Choices',
    target: '_blank',
    icon: {
      url: '/assets/us_privacy_choices_icon.svg'
    }
  };
  return <li key={'msGroupRef'} data-sentry-component="renderMicrosoftGroupLink" data-sentry-source-file="index.tsx">
			<a href={li.href} target={li.target} className={styles.groupLink} aria-label={li.label} onClick={() => AITracks.footerTrackMicrosoftClick()}>
				<span>{li.label}</span>
				<StaticImage className='icon' src={li.icon.url} alt={li.label} quality={90} width={30} height={14} data-sentry-element="StaticImage" data-sentry-source-file="index.tsx" />
			</a>
		</li>;
};
const renderUSGroupLink = (openCmpPopup: TOpenCmpPopup, AITracks: any) => {
  const li = {
    label: 'Your Privacy Choices',
    icon: {
      url: '/assets/us_privacy_choices_icon.svg'
    }
  };
  return <li key={'usGroupLink' + li.label} data-sentry-component="renderUSGroupLink" data-sentry-source-file="index.tsx">
			<button type='button' className={styles.buttonGroupLink} aria-label={li.label} onClick={() => {
      AITracks.footerTrackUSPrivacyClick();
      openCmpPopup();
    }}>
				<span>{li.label}</span>
				<StaticImage className='icon' src={li.icon.url} alt={li.label} quality={90} width={30} height={14} data-sentry-element="StaticImage" data-sentry-source-file="index.tsx" />
			</button>
		</li>;
};
const renderGDPRGroupLink = (openGDPRCmpPopup: TOpenCmpPopup, AITracks: any, manageCookiesLabel: string) => {
  return <li key={'gdprGroupLink' + manageCookiesLabel} data-sentry-component="renderGDPRGroupLink" data-sentry-source-file="index.tsx">
			<button type='button' className={styles.buttonGroupLink} aria-label={manageCookiesLabel} onClick={() => {
      AITracks.footerTrackGDPRClick();
      openGDPRCmpPopup();
    }}>
				<span>{manageCookiesLabel}</span>
			</button>
		</li>;
};
const getLastGroupLinks = (lastGroup: GroupLinks[], openGDPRCmpPopup: TOpenCmpPopup, openUSPCmpPopup: TOpenCmpPopup, isGDPRCountry: boolean | null, isUS: boolean | null, isMicrosoft: boolean, AITracks: any, GDPRCookieExists: boolean, manageCookiesLabel: string) => {
  //based on geolocation (CMP requirement)
  const lastGroupLinks = lastGroup.map(ul => <ul key={'last-group'}>
			{ul.link.map((li, i) => renderGroupLink(li, i, AITracks))}
			{/* put custom link(s) at the bottom */}
			{isMicrosoft && renderMicrosoftGroupLink(AITracks)}
			{!isMicrosoft && isUS && renderUSGroupLink(openUSPCmpPopup, AITracks)}
			{(isGDPRCountry || GDPRCookieExists) && renderGDPRGroupLink(openGDPRCmpPopup, AITracks, manageCookiesLabel)}
		</ul>);
  return lastGroupLinks;
};
const GroupLinks = (groups: GroupLinks[] | null, openGDPRCmpPopup: TOpenCmpPopup, openUSPCmpPopup: TOpenCmpPopup, isGDPRCountry: boolean | null, isUS: boolean | null, isMicrosoft: boolean, AITracks: any, manageCookiesLabel: string | null) => {
  const [GDPRCookieExists, setGDPRCookieExists] = useState<boolean>(false);
  useEffect(() => {
    setGDPRCookieExists(LocalStorageService.getItem(LSKeys.popupGDPRCookieConsent, true).length > 0);
  }, []);
  if (!groups) return null;

  //prepare html first for all generic links before last group
  const groupsBeforeLast = groups.slice(0, groups.length - 1);
  const groupsBeforeLastUI = groupsBeforeLast.map((ul, index) => <ul key={`group-${index}`}>{ul.link.map(li => renderGroupLink(li, index, AITracks))}</ul>);
  const lastGroup = groups.slice(-1);
  const lastGroupLinks = getLastGroupLinks(lastGroup, openGDPRCmpPopup, openUSPCmpPopup, isGDPRCountry, isUS, isMicrosoft, AITracks, GDPRCookieExists, manageCookiesLabel ?? 'Manage Cookies');
  return [...groupsBeforeLastUI, ...lastGroupLinks];
};
export const Footer: React.FC<FooterProps> = ({
  attributes: {
    logo,
    backgroundColor,
    groups,
    socialLinks,
    fontColor,
    text,
    manageCookiesLabel
  }
}) => {
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const isSidebarHidden = useUnit($isSidebarHidden);
  const isMobileView = !isServer && window && (window as any).innerWidth <= 568;
  const gameState = useUnit($gameStateStore);
  const footerClass = classNames(styles.footer, {
    [styles.sidebarClosedWithIcons]: !isSidebarHidden,
    [styles.hideFooter]: gameState === GameState.GAME && isMobileView
  });
  const footerTheme = () => {
    return `     
        footer {
            background-color: ${backgroundColor || '#000'}
        }

        footer .container ul li a, 
        footer .container ul li button, 
        footer .container p {
            color: ${fontColor || '#fff'}
        }
            `;
  };
  const currentYear = new Date().getFullYear();
  const imageSrc = () => {
    if (logo?.url == 'undefined' || logo?.url == null) return <></>;
    return <Image src={logo?.url} alt='Company logo' quality={90} width={0} height={0} objectFit='contain' data-sentry-element="Image" data-sentry-component="imageSrc" data-sentry-source-file="index.tsx" />;
  };
  const openGDPRCmpPopup = useUnit(openGDPRCmpPopupFx);
  const openUSPCmpPopup = useUnit(openUSPCmpPopupFx);
  const isGDPRCountry = useUnit($isGDPRApplies);
  const isUS = useUnit($isUS);
  const isMicrosoft = useUnit($isMicrosoftDomain);
  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = footerTheme();
    document.head.appendChild(styleTag);
    // Cleanup the style tag on unmount to prevent memory leaks
    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);
  return <>
			<footer className={footerClass}>
				<div className={classNames(styles.container, 'container')}>
					<div className={styles.block}>
						{logo && <div className={styles.image}>{imageSrc()}</div>}
						<p className='margin-16-bottom'>
							{' '}
							&copy; {currentYear} {text}
						</p>
						<div className={styles.social}>
							<ul className={styles.links}>{SocialLinks(socialLinks, AITracks)}</ul>
						</div>
					</div>
					<div className={styles.blocks}>
						{GroupLinks(groups, openGDPRCmpPopup, openUSPCmpPopup, isGDPRCountry, isUS, isMicrosoft, AITracks, manageCookiesLabel)}
					</div>
				</div>
			</footer>
		</>;
};